





















































































































































































































  import { debounceInput, downloadFileURL } from '@/shared/helpers'
  import { ICertificate } from '@/shared/model/certificate.model'
  import { CertificationAuthorityRole, ICertificationAuthority } from '@/shared/model/certificationAuthority.model'
  import { User } from '@/shared/model/user.model'
  import certificationService from '@/shared/services/certificationService'
  import userService from '@/shared/services/userService'
  import axios from 'axios'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default Vue.extend({
    name: 'CertificatesTable',

    components: {
      CertificateWizard: () => import('@/views/app/certificate/CertificateWizard.vue'),
    },

    props: {
      userProfileMode: {
        type: Boolean,
        default: () => false,
      },
      dashboardMode: {
        type: Boolean,
        default: () => false,
      },
    },
    data () {
      return {
        ...mapGetters({
          account: 'account',
          certificationAuthorities: 'certificationAuthorities',
          certificationAuthority: 'selectedCertificationAuthority',
          certificateTemplates: 'certificateTemplateStore/getList',
        }),
        options: {} as any,

        dialog: false,
        selectedUser: new User(),
        userService: userService,

        search: '',
        certificates: [] as ICertificate[],
        totalCertificates: 0,
        createCertificateDialog: false,
        debounce: undefined as number | undefined,

        selectedCertificationAuthorityAutocomplete: undefined as ICertificationAuthority | undefined,
      }
    },

    computed: {
      userIsSystemAdmin () {
        return userService.userIsSystemAdmin()
      },
      userRoleIsRO () {
        const userFromCertAuth = this.certificationAuthority().users.find((x: any) => x.user._id === this.account()._id)
        if (userFromCertAuth) {
          if (
            userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.trainer) ||
            userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin) ||
            this.userIsSystemAdmin
          ) {
            return false
          }
        }
        return true
      },
      headers (): any {
        return [
          {
            text: this.$t('date'),
            value: 'date',
          },
          {
            text: this.$t('due-date'),
            value: 'dueDate',
          },
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('issued-by'),
            value: 'authorObject.username',
          },
          {
            text: this.$t('user'),
            value: 'userObject.username',
          },
          {
            sortable: false,
            text: this.$t('action'),
            value: 'action',
            align: 'end',
          },
        ]
      },
      adminHeaders (): any {
        return [
          {
            text: this.$t('certification-authority'),
            value: 'certification-authority',
          },
          {
            text: this.$t('date'),
            value: 'date',
          },
          {
            text: this.$t('due-date'),
            value: 'dueDate',
          },
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('issued-by'),
            value: 'authorObject.username',
          },
          {
            text: this.$t('user'),
            value: 'userObject.username',
          },
          {
            sortable: false,
            text: this.$t('action'),
            value: 'action',
            align: 'end',
          },
        ]
      },
      showAutocomplete () {
        if (this.$route.params.id) {
          return false
        }
        if (!this.certificationAuthorities()) {
          return false
        }
        if (this.dashboardMode) {
          return false
        }
        if (this.$route.path === '/app/certification-authority') {
          return false
        }
        if (this.$route.path === '/app/user') {
          return false
        }
        return true
      },
    },
    watch: {
      options: {
        async handler () {
          await this.getAllCertificatesWithVDataTable()
        },
      },
      search: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllCertificatesWithVDataTable)
        },
      },
      selectedCertificationAuthorityAutocomplete () {
        this.$store.commit('setSelectedCertificationAuthority', this.selectedCertificationAuthorityAutocomplete)
      },
      '$store.getters.selectedCertificationAuthority': {
        async handler () {
          if (!this.selectedCertificationAuthorityAutocomplete && this.certificationAuthority()) {
            this.selectedCertificationAuthorityAutocomplete = this.certificationAuthority()
          }
          if (this.certificationAuthority() && this.certificationAuthority()._id) {
            certificationService.getCertificateTemplates(this.$store, this.certificationAuthority()._id)
            this.getAllCertificatesWithVDataTable()
          }
        },
      },
    },
    mounted () {
      if (this.certificationAuthority() && this.certificationAuthority()._id) {
        certificationService.getCertificateTemplates(this.$store, this.certificationAuthority()._id)
      }
      if (!this.selectedCertificationAuthorityAutocomplete && this.certificationAuthority()) {
        this.selectedCertificationAuthorityAutocomplete = this.certificationAuthority()
      }
    },
    methods: {
      ...mapActions('certificateStore', {
        deleteCertificate: 'deleteItem',
      }),
      async getAllCertificatesWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0] ? this.options.sortBy[0] : 'date',
          so: this.options.sortDesc[0] ? 'asc' : 'desc',
          search: { search: this.search },
        }
        await this.getList(query)
      },
      certificateIsOverDueDate (date: Date) {
        return dayjs(date).isBefore(new Date())
      },
      async getList ({ l, lo, sf, so, search = {} }: any) {
        try {
          // let url = this.userIsSystemAdmin ? '/api/certificates' : '/api/certificate'
          let url = null
          let id = this.$route.params.id
          if (!id && this.certificationAuthority()) {
            id = this.certificationAuthority()._id
          }
          if (id) {
            url = `/api/certificateByCertificationAuthority/${id}`
          }
          if (this.userProfileMode) {
            if (this.account()) {
              const id = this.$route.params.uuid ? this.$route.params.uuid : this.account()._id
              url = `/api/certificatesbyuser/${id}`
            } else {
              console.error('Edge-Case detected on logout() - the system administrator should arleady be working on it')
            }
          }
          if (this.dashboardMode) {
            url = '/api/statistics/certificates/table'
          }
          if (url) {
            const result = await axios.get(url, {
              params: { l: l, lo: lo - 1, sf, so, ...search },
            })
            if (result && result.data && result.data.items) {
              this.certificates = result.data.items
              this.totalCertificates = result.data.total
            }
          }
        } catch (err) {
          console.error(err)
        } finally {
        }
      },
      certificateCreated () {
        this.createCertificateDialog = false
        this.getAllCertificatesWithVDataTable()
      },
      clickViewCertificate (item: ICertificate) {
        this.$router.push(`/pdf/certificate/${item._id}`, () => {})
      },
      gotoprint (certificate: ICertificate) {
        if (certificate && certificate.pdfFile) {
          downloadFileURL(`/api/file/${certificate.pdfFile}`, 'Zertifikat', 'application/pdf')
        }
      },
      clickDeleteCertificate (certificate: ICertificate) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-certificate-to')}
<strong>${certificate.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('tooltip.delete-certificate').toString(),
          },
        ).then((res) => {
          if (res) {
            if (certificate._id) {
              this.deleteCertificate({ id: certificate._id, item: certificate }).then(() => {
                this.getAllCertificatesWithVDataTable()
              })
            } else {
              console.error('Tried to delete vehicle without id, this should not happen')
            }
          }
        })
      },
    },
  })
