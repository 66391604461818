var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables","fluid":"","tag":"section"}},[(_vm.showAutocomplete)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"8","md":"8"}},[_c('v-card',{staticStyle:{"padding":"15px"}},[_c('v-autocomplete',{staticStyle:{"margin-right":"30px"},attrs:{"autocomplete":Date.now(),"items":_vm.certificationAuthorities(),"color":"secondary","label":_vm.$t('certification-authority'),"validate-on-blur":"","return-object":"","item-text":"name","prepend-icon":"mdi-factory"},model:{value:(_vm.selectedCertificationAuthorityAutocomplete),callback:function ($$v) {_vm.selectedCertificationAuthorityAutocomplete=$$v},expression:"selectedCertificationAuthorityAutocomplete"}})],1)],1)],1):_vm._e(),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-certificate-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('certificates'))+" ")])]},proxy:true}])},[(!_vm.dashboardMode)?_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"server-items-length":_vm.totalCertificates,"headers":_vm.userIsSystemAdmin ? _vm.adminHeaders : _vm.headers,"items":_vm.certificates,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[(_vm.userIsSystemAdmin)?_c('td',{attrs:{"data-label":_vm.adminHeaders[0].text}},[_vm._v(" "+_vm._s(row.item.certificationAuthorityObject && row.item.certificationAuthorityObject.name)+" ")]):_vm._e(),_c('td',{attrs:{"data-label":_vm.headers[0].text}},[_vm._v(" "+_vm._s(_vm._f("formatDateForTable")(row.item.date))+" ")]),_c('td',{style:(_vm.certificateIsOverDueDate(row.item.dueDate) ? 'color: red' : 'color: green'),attrs:{"data-label":_vm.headers[1].text}},[_vm._v(" "+_vm._s(_vm._f("formatDateForTable")(row.item.dueDate))+" ")]),_c('td',{attrs:{"data-label":_vm.headers[2].text}},[_vm._v(" "+_vm._s(row.item.name)+" ")]),_c('td',{attrs:{"data-label":_vm.headers[3].text}},[_vm._v(" "+_vm._s(_vm._f("fullNameOfUser")(row.item.authorObject))+" ")]),_c('td',{attrs:{"data-label":_vm.headers[4].text}},[_vm._v(" "+_vm._s(_vm._f("fullNameOfUser")(row.item.userObject))+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.headers[5].text}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"right"}},[(
                  _vm.userProfileMode &&
                    row.item.certificateTemplate &&
                    row.item.certificateTemplate.trainingDataFile &&
                    !_vm.certificateIsOverDueDate(row.item.dueDate)
                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('a',_vm._g({staticClass:"mx-2",attrs:{"small":"","target":"_blank","href":("/pdf/webviewer/" + (row.item._id))}},on),[_c('v-icon',{staticStyle:{"font-size":"2rem"},attrs:{"dark":"","color":"primary"}},[_vm._v(" mdi-book-open-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.view-certificate-training-data')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(row.item.pdfFile)?_c('v-img',_vm._g({staticClass:"mx-2",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/pdf-icon.png"),"max-width":"30"},on:{"click":function($event){return _vm.gotoprint(row.item)}}},on)):_c('v-img',_vm._g({staticClass:"mx-2",staticStyle:{"opacity":"0.4"},attrs:{"src":require("@/assets/pdf-icon.png"),"max-width":"30"}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.pdfFile ? _vm.$t('tooltip.view-certificate') : _vm.$t('tooltip.no-pdf-found')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(row.item._id && _vm.userIsSystemAdmin)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.clickDeleteCertificate(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-certificate')))])])],1)])])]}}])}),_c('v-divider'),(!_vm.userProfileMode)?_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[(_vm.certificateTemplates() && _vm.certificateTemplates().length && !_vm.userRoleIsRO)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":function($event){_vm.createCertificateDialog = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3879494816)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-certificate')))])]):_c('div',[(!_vm.dashboardMode && !_vm.certificateTemplates())?_c('p',{staticStyle:{"color":"darkred","font-weight":"bold","margin":"8px"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.your-certification-authority-does-not-have-templates'))+" ")]):_vm._e()])],1):_vm._e()],1),_c('v-dialog',{model:{value:(_vm.createCertificateDialog),callback:function ($$v) {_vm.createCertificateDialog=$$v},expression:"createCertificateDialog"}},[(_vm.createCertificateDialog)?_c('certificate-wizard',{on:{"wizardcanceled":function($event){_vm.createCertificateDialog = false},"wizardfinished":_vm.certificateCreated}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }